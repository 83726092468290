@import '../../../assets/css/_config.scss';

.Header {
  --Header-textColor: var(--c-black);

  position: relative;
  display: flex;
  align-items: center;
  background-color: var(--c-white);
  height: var(--header-height);
  z-index: var(--zindex-header);
  transition: 200ms background-color;

  &.has-transparency {
    --Header-textColor: var(--c-white);
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 100%);
    background-color: rgba(var(--c-white-rgb), 0);
  }
}

.Logo {
  display: flex;
  align-items: center;
  margin-right: auto;
}

.Logo-em {
  width: var(--header-height);

  &.is-big {
    --header-height: #{rem(110px)};
  }

  &.is-center {
    margin: 0 auto rem(30px);
  }
}

.Logo-makersboard {
  display: block;
  width: rem(118px);
  height: rem(14px);
  fill: var(--Header-textColor);
  margin-left: rem(20px);
  margin-right: rem(20px);
}
