@import '../../../../assets/css/_config.scss';

.LoginForm {
  @include media-breakpoint-up(md) {
    margin: 0 1.5em;
  }
}

.LoginForm-instruction-text {
  color: var(--c-grey-dark);
  font-family: var(--fontFamily-secondary);
  font-size: var(--fontSize-sm);
  text-align: center;
  margin: 1em 0;
}

.LoginForm-login {
  margin-top: em(64px);
}

.LoginForm-actions {
  display: flex;
  flex-direction: column;
  margin-top: 1em;
  flex-wrap: wrap;
  align-items: stretch;

  & > * {
    width: 100%;
    margin-top: 1em;
    margin-bottom: 1em;
  }

  @include media-breakpoint-up(md) {
    & > * {
      width: auto;
    }
  }

  &.is-inline {
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: rem(16px);
  }
}

.LoginForm-errors {
  margin: 1em 0 2em;
}

.LoginForm-choices {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: rem(16px);
  margin: em(45px) 0 1em;

  @include media-breakpoint-up(md) {
    flex-direction: row;
  }
}

.LoginForm-choice {
  flex: 1 0 auto;
  font-family: var(--fontFamily-secondary);
  font-weight: var(--fontWeight-bold);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: rem(15px);
  line-height: 1.2;
  padding: rem(12px) rem(24px);
  color: var(--c-grey-dark);
  background-color: var(--c-grey-light);
  border: 2px solid var(--c-grey-light);
  border-radius: rem(5px);
  transition: color 0.3s, border 0.3s, background-color 0.3s, color 0.3s;

  &.is-active {
    color: var(--c-primary);
    background-color: var(--c-white);
    border: 2px solid currentColor;

    &:hover {
      background-color: rgba(var(--c-primary-rgb), 0.05);
      border-color: currentColor;
    }
  }

  &:hover {
    cursor: pointer;
    background-color: var(--c-grey-semi);
    border-color: var(--c-grey-semi);
  }
}

.LoginForm-reset {
  align-self: flex-end;
  font-family: var(--fontFamily-secondary);
  font-size: var(--fontSize-sm);
  font-weight: var(--fontWeight-bold);
  text-align: right;
}

.LoginForm-submit {
  justify-content: center;
  font-family: var(--fontFamily-secondary);
}

.LoginForm-success {
  display: flex;
  align-items: center;
  gap: rem(16px);
  padding: rem(8px) rem(16px);
  background-color: var(--c-grey-semi);
  border-radius: rem(5px);
}
