@import '../../../../assets/css/_config.scss';

.LandingLayout {
  --landing-foreground: var(--c-black);
  --landing-background: var(--c-white);

  background: var(--landing-background);
  color: var(--landing-foreground);
  max-width: 38em;
  padding: 2em 1em;
  position: relative;
  margin: 1em;

  &.is-left,
  &.is-right {
    @include media-breakpoint-up(md) {
      padding-left: 3em;
      padding-right: 3em;
      position: fixed;
      margin: 0;

      bottom: 0;
      top: 0;
      overflow: auto;
    }
  }

  &.is-left {
    left: 0;
  }

  &.is-right {
    right: 0;
  }

  &.is-center {
    max-width: none;
    padding: 1em;
    border-radius: rem(10px);
    margin: 0;

    @include media-breakpoint-up(md) {
      width: calc(100vw - (#{rem(32px) * 2}));
      max-width: 50em;
      padding: 2em 1em;
      margin: calc(#{rem(44px)} + #{rem(24px)} + #{rem(32px)}) 0 rem(32px);
    }
  }
}

.LandingLayout-wrapper {
  min-height: 100%;
  justify-content: center;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.LandingLayout-title {
  align-items: center;
}

.LandingLayout-caption {
  text-align: center;
  font-size: inherit;
  margin-top: em(8px);
}
