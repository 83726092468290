@import '../../../assets/css/_config.scss';

.LangSwitcher {
  background-color: var(--c-white);
  padding: rem(8px) rem(16px);
  border: 1px solid var(--c-grey-semi);
  border-radius: rem(5px);

  &.is-floatingMd {
    align-self: flex-end;
    margin-bottom: rem(48px);

    @include media-breakpoint-up(md) {
      position: absolute;
      right: 0;
      bottom: calc(100% + #{rem(24px)});
      margin-bottom: 0;
    }
  }
}

.LangSwitcher-list {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: rem(8px);
}

.LangSwitcher-item {
  display: inline-flex;
  align-items: center;

  & + & {
    &::before {
      content: "";
      display: inline-block;
      height: 0.8em;
      width: 1px;
      background-color: var(--c-grey);
      transform: translateX(calc((50% + #{rem(4px)}) * -1));
    }
  }
}

.LangSwitcher-lang {
  font-family: var(--fontFamily-secondary);
  text-transform: capitalize;
  background-color: transparent;
  border: none;
  border-radius: rem(5px);
  transition: background-color 0.3s;

  &.is-active {
    color: var(--c-primary);
    font-weight: var(--fontWeight-bold);
  }

  &:hover,
  &:focus-visible {
    cursor: pointer;
    background-color: var(--c-grey-light);
  }
}
