@import '../../../assets/css/_config.scss';

@mixin button-default {
  --button-textColor: var(--c-black);
  --button-backgroundColor: var(--c-white);
  --button-borderColor: rgba(var(--c-black-rgb), 0.2);
  --button-borderRadius: #{rem(24px)};

  --button-textColorHover: var(--c-white);
  --button-backgroundColorHover: var(--c-secondary);
  --button-borderColorHover: var(--c-secondary);

  --button-padding-block: #{rem(6px)};
  --button-padding-inline: #{rem(20px)};

  font-family: var(--fontFamily-primary);
  font-weight: var(--fontWeight-normal);
  display: inline-flex;
  column-gap: rem(5px);
  align-items: center;
  font-size: rem(15px);
  line-height: 1.2;
  letter-spacing: normal;
  text-decoration: none;
  padding: var(--button-padding-block) var(--button-padding-inline);
  color: var(--button-textColor);
  background-color: var(--button-backgroundColor);
  border: 1px solid var(--button-borderColor);
  border-radius: var(--button-borderRadius);
  transition: border 0.3s, background-color 0.3s, color 0.3s;

  &:disabled,
  &.is-disabled {
    opacity: 0.5;
    cursor: not-allowed !important;
  }
}

@mixin button-hover {
  cursor: pointer;
  --button-textColor: var(--button-textColorHover);
  --button-backgroundColor: var(--button-backgroundColorHover);
  --button-borderColor: var(--button-borderColorHover);
}

@mixin button-primary {
  --button-textColor: var(--c-white);
  --button-backgroundColor: var(--c-primary);
  --button-borderColor: var(--c-primary);

  --button-textColorHover: var(--c-black);
  --button-backgroundColorHover: var(--c-white);
  --button-borderColorHover: rgba(var(--c-black-rgb), 0.2);
}

@mixin button-secondary {
  --button-textColor: var(--c-white);
  --button-backgroundColor: var(--c-secondary);
  --button-borderColor: var(--c-secondary);

  --button-textColorHover: var(--c-black);
  --button-backgroundColorHover: var(--c-white);
  --button-borderColorHover: rgba(var(--c-black-rgb), 0.2);
}

@mixin button-xl {
  --button-padding-block: #{rem(16px)};
  --button-padding-inline: #{rem(24px)};
}

@mixin button-lg {
  --button-padding-block: #{rem(10px)};
  --button-padding-inline: #{rem(24px)};
}

@mixin button-sm {
  --button-padding-block: #{rem(4px)};
  --button-padding-inline: #{rem(16px)};
}

@mixin button-squared {
  --button-borderRadius: #{rem(5px)};
}

.Cta {
  @include button-default;

  &.is-primary {
    @include button-primary;
  }

  &.is-secondary {
    @include button-secondary;
  }

  &.is-sm {
    @include button-sm;
  }

  &.is-lg {
    @include button-lg;
  }

  &.is-xl {
    @include button-xl;
  }

  &.is-squared {
    @include button-squared;
  }
}

a.Cta,
button.Cta {
  &:hover,
  &:focus {
    @include button-hover;
  }
}

.Cta-icon {
  display: block;
  height: rem(24px);
  width: rem(24px);
  fill: currentColor;
}

.Cta.is-iconLeft .Cta-icon {
  margin-left: calc(var(--button-padding-inline) / -2);
}

.Cta.is-iconRight .Cta-icon {
  margin-right: calc(var(--button-padding-inline) / -2);
}
